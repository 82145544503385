import classes from './AddRemCount.module.css';
import { useContext, useEffect, useState } from 'react';
import {OrderContext} from '../../store/GenreContext';

const AddRemCount = ({id,qty, plate}) => {  
  const ctx = useContext(OrderContext);

  const {orderPlates} = ctx;
  const [x, setX] = useState(qty);
  
  useEffect(() => {
    console.log(orderPlates)
    const foundItem = orderPlates.find(item => item.menu_id === plate.menu_id);
    if(foundItem) {
      setX(foundItem.count);
    }else {
      setX(0);
    }
  },[orderPlates,qty])
  function onClickAddPlate(e) {
      ctx.onClickAddOne(plate);
  }
  
  function onClickRemovePlate(e) {
     ctx.onClickRemoveOne(plate);
  }

  return (
    <div style={{"textAlign": "right"}}>
    {
      x === 0 ? "" :  <span className={classes.pm} onClick={onClickRemovePlate}><i style={{color: "#3E3E3F"}} className="fa-solid fa-circle-minus"></i></span>
    }
      
      {x === 0 ? <span className='p-2'></span> : <input readOnly value={x} className={classes.counter} />}
      <span className={classes.pm} onClick={onClickAddPlate}><i style={{color: "#3E3E3F"}} className="fa-solid fa-circle-plus"></i></span>
    </div>
  )


}

export default AddRemCount;